import { V2_MetaFunction } from '@remix-run/node'
import { Link } from '@remix-run/react'
import { useOptionalUser } from '~/utils'
import ReactPlayer from 'react-player'

export const meta: V2_MetaFunction = () => [{ title: 'ACM' }]

export default function Index() {
  const user = useOptionalUser()
  return (
    <section className="">
      {!user ?
        (
        <>
        <div className="mx-auto px-4 bg-secondary bg-pattern">
          <div className="relative mb-0 pt-16 pb-16 md:pt-12 md:pb-12">
            <div className="mx-auto mb-0 max-w-2xl text-center">
              <h2 className="font-heading mb-4 text-4xl font-bold leading-tight text-white drop-shadow-md md:text-4xl md:leading-tight lg:text-6xl lg:leading-tight">
                Make your brand shine with ACM.
              </h2>
              <p className="mb-8 text-base leading-relaxed text-white lg:text-xl lg:leading-relaxed">
                Welcome to ACM! As your TV buying agency, we help you get your TV ads to the right people at the right time.
              </p>
              <p className="mb-8 text-base leading-relaxed text-white lg:text-xl lg:leading-relaxed">
                Ready to begin? Simply sign up using our new client form, pick your target market and region, and set your budget.
              </p>
              <p className="mb-8 text-base leading-relaxed text-white lg:text-xl lg:leading-relaxed">
                Don’t have an ad? No problem, you can make one for free with our built-in creative tool in as little as 10 minutes.
              </p>
              
              
            </div>
            
          </div>
        </div>
        <h2 className="font-heading mb-4 mt-4 text-center pt-5 text-4xl font-bold leading-tight text-primary drop-shadow-md md:text-4xl md:leading-tight lg:text-6xl lg:leading-tight">
                See How Easy it is
              </h2>
              <div className="mt-10 mb-2 w-full max-w-full h-[378px] container">
                {/* <ReactPlayer width='100%'
                  height='100%' url='/engageTVPlatformInfo.mp4' controls={true} /> */}
                <iframe width="100%" height="100%" src="https://player.vimeo.com/video/1011161309" title="ACM Ad Manager" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>

              <div className="text-center">
                  <p className="py-8 text-base leading-relaxed text-primary lg:text-xl lg:leading-relaxed">
                    Let’s get started!
                  </p>

                  <div>
                    <div>
                      <Link
                        className="mb-3 block rounded-lg bg-secondary px-5 py-3 text-center text-sm font-semibold text-white transition duration-200 hover:bg-lightBlue hover:shadow-lg md:mr-3 md:mb-0 md:inline-block"
                        to="/signup"
                      >
                        Create a free account
                      </Link>
                    </div>
                  </div>
              </div>

              <div className="relative mx-auto mt-12 max-w-3xl mb-12">
                  <img
                    className="relative top-0 left-0 mx-auto h-80 w-full rounded-xl object-cover md:h-96"
                    src="assets/illustrations/engineering-team.svg"
                    alt=""
                  />
              </div>

        </>
      )
        :
        <div className="container mx-auto px-4">
          <div className="relative mb-0 pt-12 pb-4 md:pb-32 md:pt-16 lg:pb-48">
            <div className="mx-auto mb-4 md:mb-16 max-w-2xl text-left">
              <h2 className="font-heading mb-8 text-center text-4xl font-bold leading-tight text-primary drop-shadow-md md:text-4xl md:leading-tight lg:text-6xl lg:leading-tight">
                Welcome
              </h2>
              <p className="mb-2 text-base leading-relaxed text-primary lg:text-xl lg:leading-relaxed">
                Thanks for signing up and welcome to ACM!
              </p>
              <p className="mb-8 text-base leading-relaxed text-primary lg:text-xl lg:leading-relaxed">
                You’re one step closer to reaching your target audience and growing your brand.
              </p>
              <p className="mb-8 text-base leading-relaxed text-primary lg:text-xl lg:leading-relaxed">
                Have your ad ready to go? That’s great, let’s <Link className="underline" to="/campaigns">get started!</Link>
              </p>
              <p className="mb-8 text-base leading-relaxed text-primary lg:text-xl lg:leading-relaxed">
                For those needing to create an ad, our built-in creative tool will have you up and running in just 5 easy steps.
              </p>
              <p className="mb-8 text-base leading-relaxed text-primary lg:text-xl lg:leading-relaxed">
                For tips and advice on how to achieve the best results when creating your ad, watch the video below. It cover’s important details, like structuring your video, image quality, voiceover, music, and end frame.
              </p>
              <div>
                <div className='text-center'>
                  <Link
                    className="mb-3 text-center block rounded bg-secondary px-5 py-3 text-center text-sm font-semibold text-white transition duration-200 hover:bg-lightBlue hover:shadow-lg md:mr-3 md:mb-0 md:inline-block"
                    to="/campaigns"
                  >
                    View campaigns for {user.email}
                  </Link>
                </div>
              </div>
            </div>

          </div>
        </div>
      }
    </section>
  )
}
